<template>
  <div class="container-fluid user-setting-lottosets">
    <div class="page-header mb-1">
      <strong>ตั้งค่าหวยชุด</strong>
    </div>
    <div class="card">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li v-for="market in markets" :key="market._id" class="nav-item">
            <a class="nav-link" :class="[{'active':tabActiveMarketId===market._id}]" href="#" target="_self" @click="tabActiveMarketId=market._id">{{ market.marketTitle }}</a>
          </li>
        </ul>
      </div>
      <div class="p-2">
        <table class="table-accounts table table-hover table-bordered mb-0">
          <thead>
            <tr>
              <th class="text-center" rowspan="2">ลำดับ</th>
              <th class="text-center" rowspan="2">ชื่อใช้งาน</th>
              <th class="text-center" rowspan="2">ระดับ</th>
              <th class="text-center" rowspan="2">เปิด/ปิด</th>
              <th class="text-center" rowspan="2">ราคา<br />(บาท)</th>
              <th class="text-center" rowspan="2">ส่วนลด<br />(บาท)</th>
              <th :colspan="marketOpenBets.length" class="text-center">จ่าย (บาท)</th>
              <th class="text-center" rowspan="2">แก้ไข</th>
            </tr>
            <tr>
              <th
                v-for="bet in marketOpenBets"
                :key="bet.code"
                class="text-center"
                v-if="isOpens[bet.code]"
              >{{ bet.text }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(account, index) in accountItems" :key="account._id">
              <td class="text-center">{{ index+1 }}</td>
              <td class="text-center">{{ account.username }}</td>
              <td class="text-center">{{ account.levelName }}</td>
              <td class="text-center" :class="[{'alert-dark': !account.groupAvailable}]">
                <b-form-checkbox
                  v-model="account.isAvailable"
                  name="check-button"
                  switch
                  @change="toggleChange(account)"
                  :class="[account.isAvailable ? 'text-success' : 'text-danger']"
                  :disabled="!account.groupAvailable"
                >
                  {{ account.isAvailable ? 'เปิด' : 'ปิด' }}
                </b-form-checkbox>
              </td>
              <td class="text-right text-success" :class="[{'alert-dark': !account.isAvailable}]">{{ account.price | amountFormat(2, '0.00') }}</td>
              <td class="text-right text-danger" :class="[{'alert-dark': !account.isAvailable}]">{{ account.discount | amountFormat(2, '0.00') }}</td>

              <td
                v-for="bet in marketOpenBets"
                :key="bet.code"
                class="text-right text-info"
                :class="[{'alert-dark': !account.isAvailable}]"
                v-if="isOpens[bet.code]"
              >
                <span v-if="account.isAvailable">{{account.prizes[bet.code] | amountFormat(2, '0.00')}}</span>
                <span v-else>-</span>
              </td>

              <td class="text-center" :class="[{'alert-dark': !account.isAvailable}]">
                <button :disabled="!account.isAvailable" class="btn btn-sm btn-outline-primary" @click="toggleSetting(account)"><i class="far fa-edit"></i></button>
              </td>
            </tr>
            <tr v-if="!accountItems.length">
              <td class="text-center" :colspan="marketOpenBets.length + 8">ไม่มีข้อมูล</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <EditLottosetModal :is-show="isShowModal" :data="editData" @close="modalClosed" />
  </div>
</template>
<script>
import AccountService from "@/services/accountService"
import MarketService from '@/services/marketService'
import Swal from 'sweetalert2'
import { marketTypes } from '@/configs/market.config'
import EditLottosetModal from './components/EditLottosetModal'

export default {
  name: 'Lottosets',
  components: {
    EditLottosetModal
  },
  data() {
    return {
      tabActiveMarketId: null,
      accounts: [],
      markets: [],
      isShowModal: false,
      editData: null
    }
  },
  computed: {
    market() {
      if(!this.tabActiveMarketId)
        return null

      return this.markets.find(market=>market._id===this.tabActiveMarketId)
    },
    accountItems() {
      if(this.market) {
        return this.accounts.map((acc)=>{
          const accPrizes = acc?.markets?.[this.tabActiveMarketId]?.prizes || {}
          const groupAvailable = acc?.groups[this.market.groupId]?.isAvailable || false
          const isAvailable = acc?.markets?.[this.tabActiveMarketId]?.isAvailable === false ? false : true

          return {
            _id: acc._id,
            username: acc.user.username,
            levelName: acc.level.levelName,
            marketId: this.tabActiveMarketId,
            groupAvailable: groupAvailable,
            isAvailable: groupAvailable === false ? false : isAvailable,
            price: acc?.markets?.[this.tabActiveMarketId]?.price ? acc?.markets?.[this.tabActiveMarketId]?.price : this.market?.lottoSet?.price,
            discount: acc?.markets?.[this.tabActiveMarketId]?.discount ? acc?.markets?.[this.tabActiveMarketId]?.discount : this.market?.lottoSet?.discount,
            prizes: {
              ...this.market.lottoSet.prizes,
              ...accPrizes
            },
            marketTitle: this.market.marketTitle
          }
        })
      }else{
        return []
      }
    },
    marketOpenBets() {
      if(!this.market)
        return []

      const marketType = marketTypes.find(t=>t.code===this.market.marketType)
      if(!marketType)
        return []

      const set = marketType.sets.find(s=>s.set===this.market.marketSet)
      if(!set)
        return []

      return (set?.openBets || []).filter((bet)=>{
        return this.market?.openBets?.[bet.code]
      })
    },
    isOpens() {
      return this.marketOpenBets.reduce((openBets, bet)=>{
        openBets[bet.code] = this.market?.openBets?.[bet.code] || false
        return openBets
      }, {})
    }
  },
  methods: {
    loadData() {
      AccountService.getAccountSettingLottosets()
      .then((response)=>{
        if(response.success) {
          this.accounts = response.data.accounts
          this.markets = response.data.markets

          if(!this.tabActiveMarketId) {
            this.tabActiveMarketId = this.markets[0]?._id
          }
        }
      })
    },
    toggleChange(item) {
      console.log(item._id, item.isAvailable, item.marketId)
      this.save({
        accountIds: [item._id],
        marketIds: [item.marketId],
        isAvailable: item.isAvailable
      })
    },
    save(data) {
      AccountService.saveAccountSettingMarkets(data)
      .then((response)=>{
        if(!response.success) {
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        console.log(e.message)
        Swal.fire({
          title: 'ไม่สำเร็จ!',
          text: e.message,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.loadData()
      })
    },
    toggleSetting(item) {
      const prizes = this.marketOpenBets.map((bet)=>{
        return {
          ...bet,
          prize: item.prizes?.[bet.code] || 0
        }
      })
      this.editData = {
        ...item,
        prizes: prizes
      }
      this.isShowModal = true
    },
    modalClosed(needReload) {
      this.isShowModal = false
      if(needReload) {
        this.loadData()
      }
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>
<style lang="scss" scoped>
.user-setting-lottosets {
  table {
    th {
      border-top: 0;
      font-size: 90%;
      vertical-align: middle;
    }
    td {
      vertical-align: middle;
    }
  }
}
</style>
